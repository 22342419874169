<template>
  <div class="basucInfoPage">
    <el-form
      :model="basicInfoForm"
      :rules="vehicleRule"
      ref="basicInfoForm"
      label-width="150px"
    >
      <!-- 认证资料 -->
      <div class="title">认证信息</div>
      <div class="content">
        <div class="upBox">
          <UploadImg
            :loading="authForm.legalPersonIDCardOn.loading"
            :uploaded="authForm.legalPersonIDCardOn.uploaded"
            :picUrl="authForm.legalPersonIDCardOn.picUrl"
            @update:file="updateFile"
            @change="uploadLegalPersonIDCardOn(authForm.legalPersonIDCardOn)"
            @deleteImg="deleteImg(authForm.legalPersonIDCardOn)"
          >
          </UploadImg>
          <div class="upText">
            法人身份证正面 <span style="color: red">*</span>
          </div>
        </div>
        <div class="upBox">
          <UploadImg
            :loading="authForm.legalPersonIDCardBack.loading"
            :uploaded="authForm.legalPersonIDCardBack.uploaded"
            :picUrl="authForm.legalPersonIDCardBack.picUrl"
            @update:file="updateFile"
            @change="
              uploadLegalPersonIDCardBack(authForm.legalPersonIDCardBack)
            "
            @deleteImg="deleteImg(authForm.legalPersonIDCardBack)"
          >
          </UploadImg>
          <div class="upText">
            法人身份证反面 <span style="color: red">*</span>
          </div>
        </div>
        <div class="upBox">
          <UploadImg
            :loading="authForm.businessLicense.loading"
            :uploaded="authForm.businessLicense.uploaded"
            :picUrl="authForm.businessLicense.picUrl"
            @update:file="updateFile"
            @change="uploadBusinessLicense(authForm.businessLicense)"
            @deleteImg="deleteImg(authForm.businessLicense)"
          >
          </UploadImg>
          <div class="upText">
            营业执照(原件三合一正本) <span style="color: red">*</span>
          </div>
        </div>
      </div>
      <div class="content" style="margin: 26px 0 0 0">
        <div class="upBox">
          <UploadImg
            :loading="authForm.rTBusinessLicense.loading"
            :uploaded="authForm.rTBusinessLicense.uploaded"
            :picUrl="authForm.rTBusinessLicense.picUrl"
            @update:file="updateFile"
            @change="uploadRtBusinessLicense(authForm.rTBusinessLicense)"
            @deleteImg="deleteImg(authForm.rTBusinessLicense)"
          >
          </UploadImg>
          <div class="upText">
            道路经营许可证<span style="color: red">*</span>
          </div>
        </div>
        <div class="upBox">
          <UploadImg
            :loading="authForm.legalPersonBankCard.loading"
            :uploaded="authForm.legalPersonBankCard.uploaded"
            :picUrl="authForm.legalPersonBankCard.picUrl"
            @update:file="updateFile"
            @change="uploadLegalPersonBankCard(authForm.legalPersonBankCard)"
            @deleteImg="deleteImg(authForm.legalPersonBankCard)"
          >
          </UploadImg>
          <div class="upText">企业银行卡<span style="color: red">*</span></div>
        </div>
        <div class="upBox">
          <UploadImg
            :loading="authForm.oherCertificate.loading"
            :uploaded="authForm.oherCertificate.uploaded"
            :picUrl="authForm.oherCertificate.picUrl"
            @update:file="updateFile"
            @change="uploadOherCertificate(authForm.oherCertificate)"
            @deleteImg="deleteImg(authForm.rTBusinessLicense)"
          >
          </UploadImg>
          <div class="upText">其他附件</div>
        </div>
      </div>
      <!-- 基本信息 -->
      <div class="title">基本信息</div>
      <div class="content content1" style="justify-content: left">
        <el-form-item label="法人姓名" prop="legalPersonIName">
          <el-input
            v-model="basicInfoForm.legalPersonIName"
            placeholder="请输入法人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号" prop="legalPersonIIDCard">
          <el-input
            v-model="basicInfoForm.legalPersonIIDCard"
            @blur="blurIdCard"
            placeholder="请输入法人身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人身份证有效期" prop="legalPersonlIDCardDate">
          <el-date-picker
            v-model="basicInfoForm.legalPersonlIDCardDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择法人身份证有效期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="法人手机号码" prop="legalPersonlPhone">
          <el-input
            v-model="basicInfoForm.legalPersonlPhone"
            @blur="blurPhone"
            placeholder="请输入法人手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业名称" prop="enterpriseFullName">
          <el-input
            v-model="basicInfoForm.enterpriseFullName"
            placeholder="请输入企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="enterpriseCreditCode">
          <el-input
            v-model="basicInfoForm.enterpriseCreditCode"
            placeholder="请输入统一社会信用代码"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照住所" prop="enterpriseAddress">
          <el-input
            v-model="basicInfoForm.enterpriseAddress"
            placeholder="请输入营业执照住所"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照注册日期" prop="enterpriseRegisterDate">
          <el-date-picker
            v-model="basicInfoForm.enterpriseRegisterDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择营业执照注册日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="营业执照有效日期" prop="enterpriseEffectiveDate">
          <el-date-picker
            v-model="basicInfoForm.enterpriseEffectiveDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择营业执照有效日期"
          ></el-date-picker>
        </el-form-item>
      </div>
      <!-- 开票信息 -->
      <div class="title">开票信息</div>
      <div class="content content1" style="justify-content: left">
        <el-form-item label="发票抬头企业全名" prop="invoiceTitleName">
          <el-input
            v-model="basicInfoForm.invoiceTitleName"
            placeholder="请输入发票企业抬头"
          ></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号" prop="invoiceTitleCreditCode">
          <el-input
            v-model="basicInfoForm.invoiceTitleCreditCode"
            placeholder="请输入纳税人识别号"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行账户" prop="invoiceTitleBankerNumber">
          <el-input
            v-model="basicInfoForm.invoiceTitleBankerNumber"
            placeholder="请输入银行基本账号"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行账户开户行" prop="invoiceTitleBankerName">
          <el-input
            v-model="basicInfoForm.invoiceTitleBankerName"
            placeholder="请输入银行账户开户行"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业联系地址" prop="invoiceTitleAddress">
          <el-input
            v-model="basicInfoForm.invoiceTitleAddress"
            placeholder="请输入企业联系地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业联系电话" prop="invoiceTitlePhone">
          <el-input
            v-model="basicInfoForm.invoiceTitlePhone"
            placeholder="请输入企业联系电话"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import UploadImg from "@/components/commonCmpt/uploadImg";
import verify from "@/utils/verify.js";
import {
  saveGoodsOwnerInfo,
  getGoodsOwnerInfo,
} from "@/api/goodsOwner/goodsOwnerManage/basicInfo";
export default {
  data() {
    return {
      currentFile: "", //当前正在操作的文件
      authForm: {
        userID: 0,
        //法人身份证(正面)
        legalPersonIDCardOn: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //法人身份证(反面)
        legalPersonIDCardBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //营业执照(原件三合一正本)照片
        businessLicense: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //道路经营许可证
        rTBusinessLicense: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //企业银行卡
        legalPersonBankCard: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //其他证件照
        oherCertificate: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      },
      basicInfoForm: {
        userID: 0,
        enterpriseContactsName: "", //企业联系人
        enterpriseContactsPhone: "", //企业联系人电话
        enterpriseCreditCode: "", //统一社会信用代码
        enterpriseFullName: "", //公司全称：发票抬头
        legalPersonIName: "", //法人代表
        legalPersonIIDCard: "", //法人身份证号
        legalPersonlPhone: "", //法人电话
        areaId: "", //地区标识
        placeAttribution: "", //所属地区
        enterpriseAddress: "", //营业执照住所
        enterpriseRegisterDate: "", //营业执照注册日期
        enterpriseEffectiveDate: "", //营业执照有效日期
        legalPersonlIDCardDate: "", //法人身份证有效日期
        rtBusinessLicense: "", //道路运输经营许可证编号
        defaultRate: "", //默认费率：国家规定的9个点
        bankerName: "", //开户银行:发票抬头（专票需要)
        bankerNumber: "", //银行账号：发票抬头（专票需要）
        status: 0, //企业状态:0未校验1校验通过2校验未通过
        remark: "", //错误原因：未通过原因
        invoiceType: "", //发票类型
        defaultRate1: "", //1%或3%票据的默认费率
        invoiceTitleName: "", //发票抬头企业全名
        invoiceTitleCreditCode: "", //纳税人识别号
        invoiceTitleBankerNumber: "", //银行基本账号
        invoiceTitleBankerName: "", //银行账户开户行
        invoiceTitleAddress: "", //企业联系地址
        invoiceTitlePhone: "", //企业联系电话
      },
      vehicleRule: {
        legalPersonIName: [
          { required: true, message: "请输入法人姓名", trigger: "change" },
        ],
        legalPersonIIDCard: [
          { required: true, message: "请输入法人身份证号", trigger: "change" },
        ],
        legalPersonlIDCardDate: [
          {
            required: true,
            message: "请选择法人身份证有效期",
            trigger: "change",
          },
        ],
        legalPersonlPhone: [
          { required: true, message: "请输入法人手机号码", trigger: "change" },
        ],
        enterpriseFullName: [
          { required: true, message: "请输入企业名称", trigger: "change" },
        ],
        enterpriseCreditCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "change",
          },
        ],
        enterpriseAddress: [
          { required: true, message: "请输入营业执照住所", trigger: "change" },
        ],
        enterpriseRegisterDate: [
          {
            required: true,
            message: "请选择营业执照注册日期",
            trigger: "change",
          },
        ],
        enterpriseEffectiveDate: [
          {
            required: true,
            message: "请选择营业执照有效日期",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    ...mapMutations(["setFullInfo"]),
    blurPhone() {
      if (verify._phone(this.basicInfoForm.legalPersonlPhone) == false) {
        this.$message({
          showClose: true,
          message: "法人手机号输入有误，请重新输入。",
          type: "error",
        });
      }
    },
    blurIdCard() {
      if (verify._isCardNo(this.basicInfoForm.legalPersonIIDCard) == false) {
        this.$message({
          showClose: true,
          message: "法人身份证号输入有误，请重新输入。",
          type: "error",
        });
      }
    },
    //保存
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            userID: this.basicInfoForm.userID, //
            enterpriseContactsName: this.basicInfoForm.enterpriseContactsName,
            enterpriseContactsPhone: this.basicInfoForm.enterpriseContactsPhone,
            enterpriseCreditCode: this.basicInfoForm.enterpriseCreditCode,
            enterpriseFullName: this.basicInfoForm.enterpriseFullName,
            legalPersonIName: this.basicInfoForm.legalPersonIName,
            legalPersonIIDCard: this.basicInfoForm.legalPersonIIDCard,
            legalPersonlPhone: this.basicInfoForm.legalPersonlPhone,
            areaId: 0,
            placeAttribution: this.basicInfoForm.placeAttribution,
            enterpriseAddress: this.basicInfoForm.enterpriseAddress,
            enterpriseRegisterDate: this.basicInfoForm.enterpriseRegisterDate,
            enterpriseEffectiveDate: this.basicInfoForm.enterpriseEffectiveDate,
            legalPersonlIDCardDate: this.basicInfoForm.legalPersonlIDCardDate,
            rtBusinessLicense: this.basicInfoForm.rtBusinessLicense,
            defaultRate: this.basicInfoForm.defaultRate,
            bankerName: this.basicInfoForm.bankerName,
            bankerNumber: this.basicInfoForm.bankerNumber,
            status: this.basicInfoForm.status,
            remark: this.basicInfoForm.remark,
            invoiceType: this.basicInfoForm.invoiceType,
            defaultRate1: this.basicInfoForm.defaultRate1,
          };
          saveGoodsOwnerInfo(params).then((res) => {
            this.setFullInfo(1);
          });
        }
      });
    },
    //获取基本信息
    getGoodsOwnerInfo() {
      getGoodsOwnerInfo({}).then((res) => {
        if (res.enterPrise) {
          this.basicInfoForm.legalPersonIName = res.enterPrise.LegalPersonIName;
          this.basicInfoForm.legalPersonIIDCard =
            res.enterPrise.LegalPersonIIDCard;
          this.basicInfoForm.legalPersonlIDCardDate =
            res.enterPrise.LegalPersonlIDCardDate;
          this.basicInfoForm.legalPersonlPhone =
            res.enterPrise.LegalPersonlPhone;
          this.basicInfoForm.enterpriseFullName =
            res.enterPrise.EnterpriseFullName;
          this.basicInfoForm.enterpriseCreditCode =
            res.enterPrise.EnterpriseCreditCode;
          this.basicInfoForm.enterpriseAddress =
            res.enterPrise.EnterpriseAddress;
          this.basicInfoForm.enterpriseRegisterDate =
            res.enterPrise.EnterpriseRegisterDate;
          this.basicInfoForm.enterpriseEffectiveDate =
            res.enterPrise.EnterpriseEffectiveDate;
          this.setFullInfo(1);
        } else {
          this.setFullInfo(0);
        }
      });
    },
  },
  created() {
    this.getGoodsOwnerInfo();
  },
  components: {
    UploadImg,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/style/variable.scss";
.title {
  color: $primary;
  position: relative;
  text-indent: 15px;
  font-size: 14px;
  border-bottom: 1px solid $borderGray;
  padding: 20px 0;
  margin-bottom: 20px;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    border-radius: 5px;
    background: $primary;
  }
}
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  .upBox {
    text-align: center;
    .upText {
      font-size: 14px;
      font-weight: bold;
      margin: 10px 0 0 0;
      color: #333333;
    }
  }
  .el-upload__tip {
    color: $textGray2;
  }
}
.basucInfoPage {
  .top {
    margin-bottom: 30px;
  }
  .el-form {
    //display: flex;
    //flex-wrap: wrap;
    .el-form-item {
      width: 400px;
    }
    .el-date-editor,
    .el-select {
      width: 100%;
    }
  }
}
</style>
